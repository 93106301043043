.imageTextCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.techSpan{
  border: plum solid 2px;
  border-radius: 10px;
  padding: 0 0.25rem;
  text-align: center;
  font-weight: bold;
}

.techSpanContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  font-size:x-small;
  text-align: center;
}

.screen-reader-text {
  position: absolute;
  width: 0.5px;
  height: 0.5px;
  overflow: hidden;
}

.projectImg {
  transition: transform .4s ease;
}

.projectImg:hover{
  transform: scale(1.1);
}

@media (min-width: 600px) {
  .imageTextCard{
    flex-direction: row;
    justify-content: space-around;
  }
}