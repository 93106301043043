.navBar{
  color: green;
  position: relative;
  align-self: flex-start;
  align-items: center;
  justify-items: center;
}

.navContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 30em;
  position: relative;
}

.light-switch-background-dark{
  background-color:orange;
  color: black;
  stroke: black;
  padding: 0.50rem;
  border-radius: 10px;
  /* position: fixed;
  right: 1rem; */


}
.light-switch-background-light{
  background-color:plum;
  color: white;
  stroke: white;
  padding: 0.50rem;
  border-radius: 10px;

}
.screen-reader-text {
  position: absolute;
  width: 0.5px;
  height: 0.5px;
  overflow: hidden;
}


@media (min-width: 600px) {
  .light-switch-background-dark{
    /* align-self:center;
    justify-self: center; */
    /* right: 8rem; */
  }
}