.formContainer {
  width: 100%;
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 30em;
}

.containerTitle {
  margin-bottom: 2rem;
}

.linksContainer {
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  padding: 0;
  margin-bottom: 2rem;
  margin-top: 2rem;
}


/******** accessibility ********/
.screen-reader-text {
  position: absolute;
  width: 0.5px;
  height: 0.5px;
  overflow: hidden;
}

.skip-links {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.skip-links a {
  position: absolute;
  top: -100rem;
  display: inline-block;
  padding: 1rem 0.5rem;
  color: white;
  border-radius: 0 0 0.5rem 0.5rem;
  text-decoration: none;
  background-color: green;
  z-index: 9000;
}

.skip-links a:focus {
  top: 0;
}

.to-top a {
  position: absolute;
  right: 0;
  bottom: 0rem;
  display: inline-block;
  padding: 1rem 0.5rem;
  color: white;
  border-radius: 0.5rem 0.5rem 0 0;
  text-decoration: none;
  background-color: green;
  z-index: 9000;
}

.to-top a:focus {
  bottom: 0;
  right: 0;
}

.required {
  color: red;
}

.btn {
  flex: 1 1 auto;
  margin: 10px auto;
  padding: 30px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  text-shadow: 0px 0px 10px rgba(0,0,0,0.2);
  border-radius: 10px;
}

.btn:hover {
  background-position: right center; /* change the direction of the change here */
}

.btn-1 {
  background-image: linear-gradient(to right, #f6d365 0%, #fda085 51%, #f6d365 100%);
}

@media (min-width: 600px) {
  .requiredField{
    font-size:large;
  }

  .linksContainer{
    justify-content: flex-start;
  }
}