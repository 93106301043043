.App-container-light{
  background-color:ghostwhite;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: #282c34 !important;
  position: relative;
  padding: 2rem 1rem;
  /* max-width: 30em; */
  gap: 2rem;
  margin: 0 auto;

}

.App-container-dark {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: ghostwhite;
  position: relative;
  padding: 2rem 1rem;
  /* max-width: 30em; */
  gap: 2rem;
  margin: 0 auto;

}

/* .light-switch-background-dark{
  background-color:orange;
  color: black;
  stroke: black;
  padding: 0.50rem;
  border-radius: 10px;
  justify-self: flex-end;
  align-self: flex-end;
} */

/* .light-switch-background-light{
  background-color:plum;
  color: white;
  stroke: white;
  padding: 0.50rem;
  border-radius: 10px;
  justify-self: flex-end;
  align-self: flex-end;

} */